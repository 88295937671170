import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"

const AlltagsgestaltungAktivierung = ({data}) => {

  const allImages = data.allImages.edges
  const title = "Alltagsgestaltung & Aktivierung"

  return (
    <Layout title={title} slogan="" headerImage={data.allImages.edges[0].node.childImageSharp.fluid}>
      <div className="grid grid-2" style={{gridTemplateColumns: '3fr 2fr'}}>
        <div>
          <h4>„Aktivierung ist eine ganzheitliche Begleitung, die den Alltag unterstützt, erleichtert und bereichert.“</h4>
          <p>
            Jeder von uns hat einen Alltag. In jeder Lebensphase sieht er anders aus: in der Kindheit, Erwachsenenalter, Pensionsalter, wie auch beim Eintritt ins Altersheim. Manche Tätigkeiten können nicht mehr selbständig ausgeführt werden, die man im Alltag braucht. Einsamkeit, Langweile, Lustlosigkeit machen sich breit. Dies kann Krankheiten auslösen. Eine aktivierende Begleitung kann dem entgegen wirken und den Alltag verschönern. Aus diesem Grund die Aktivierung im Altersheim Bannau soll den Bewohnern helfen, möglichst vieles selbst zu tun, um die Eigenständigkeit zu bewahren, durch z.B:
            <ul>
              <li>Beweglichkeit fördern beim Turnen</li>
              <li>ein Hobby pflegen mit einem Spiel- oder Jassnachmittag</li>
              <li>Lebensfreude erhalten z.B. auf einem Ausflug, Spaziergang</li>
              <li>Bücher lesen beim Vorlesen</li>
              <li>manuelle Fähigkeiten fördern beim Werken</li>
              <li>Merkfähigkeit trainieren in der Gedächtnisgruppe</li>
              <li>religiösen Bedürfnissen nachzugehen</li>
              <li>Gemeinschaftliches Zusammensein bei einem Film aus Ihrer Jugend</li>
            </ul>
            Wir achten auf die individuellen Bedürfnisse und haben Verständnis, wenn man sich nicht beteiligen möchte.
          </p>
        </div>
        <div>
          <ImageGalleryWithLightbox className="grid-image" images={allImages} />
        </div>
      </div>
    </Layout>
  ) 
}

export default AlltagsgestaltungAktivierung

export const pageQuery = graphql`
  query {
    allImages: allFile(filter: {relativeDirectory: {eq: "alltagsgestaltung"}}, sort: {order: ASC, fields: name}) {
			edges {
				node {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1600) {
							...GatsbyImageSharpFluid_withWebp
						}
					},
					name
				}
			}
		}
  }
`